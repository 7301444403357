import * as React from 'react'

import MetodeImage from '@/images/undraw/metode-pesantren-dps.jpg'
import AlurImage from '@/images/undraw/alur-pendaftaran.jpg'
import DigitalImage from '@/images/undraw/kurikulum-digitalmarketing.jpg'

const callouts = [
  {
    name: 'Penerimaan Santri Baru TA 2023/2024 Pesantren Property Syariah',
    description: 'Pesantren Property Syariah dengan Bangga Mempersembahkan SMK Jurusan Digital Marketing untuk Santri Perdana Tahun ini.',
    imageSrc: MetodeImage,
    imageAlt: 'Pesantren Property Syariah',
    href: 'https://www.youtube.com/watch?v=xsiyO9uU-90',
  },
  {
    name: 'Alur Pendaftaran Santri Baru TA 2023/2024 Pesantren Property Syariah',
    description: 'Berikut alur pendaftaran Pesantren Property Syariah TA 2023/2024.',
    imageSrc: AlurImage,
    imageAlt: 'AlurPendaftaran',
    href: '#',
  },
  {
    name: 'SMK dengan Jurusan Digital Marketing',
    description: 'Berikut pemaparan Kurikulum jurusan Digital Marketing pada acara studium general.',
    imageSrc: DigitalImage,
    imageAlt: 'Kurikulum Digital Marketing',
    href: 'https://youtube.com/clip/UgkxpnGfSTjfxp_BdMcLWOH3-M-pxaaXPwmq',
  },
]

export default function FindOutMore() {
  return (
    <div className="bg-purple-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-4xl font-extrabold text-green-800">Find Out More</h2>

          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {callouts.map(callout => (
              <div key={callout.name} className="group relative">
                <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-contain"
                  />
                </div>
                <h3 className="mt-6 text-2xl text-green-600">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base text-gray-900">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
