import * as React from 'react'
import { ClockIcon, SparklesIcon, PuzzleIcon, PresentationChartBarIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Metode Pembelajaran',
    description:
      '"Khitaabul Fikri Minal Muallim" Penyampaian ilmu dari pengajar, yang membangkitkan proses berfikir peserta didik. "Talaqqiyan Fikriyyan Minal Mutaállim" Penerimaan ilmu oleh peserta didik melalui proses berfikir',
    icon: ClockIcon,
  },
  {
    name: 'Kepribadian',
    description:
      'Pesantren Property Syariah Membentuk Syaksiah Islamiyah Santri.',
    icon: SparklesIcon,
  },
  {
    name: 'Kesiapan',
    description:
      'Menyiapkan Santri untuk melanjutkan ke jenjang Perguruan Tinggi.',
    icon: PuzzleIcon,
  },
  {
    name: 'Keterampilan',
    description:
      'Menyiapkan Santri untuk memiliki keterampilan dan pengetahuan yang dibutuhkan.',
    icon: PresentationChartBarIcon,
  },
]

export default function Features() {
  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Mengapa Harus Mondok Disini?</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Metode Belajar Komfrehensif
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-600 lg:mx-auto">
            Disamping ilmu agama, santri juga dibekali dengan skill dan kompetensi dibidang Digital Marketing dan Property Syariah
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map(feature => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
